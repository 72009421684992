<template>
  <div class="SetupQuestion">
    <div class="CardAddAnswer">
      <input
        class="QuestionField"
        v-model="question.name"
        placeholder="Вопрос"
        type="text"
      />

      <div
        class="AddVariable"
        v-for="(answer, index) in question.answers"
        :key="`${index}${answer.isRightQuestion}${answer.order}`"
      >
        <div class="SetupCheck">
          <label class="SetupCheckText">
            <template v-if="question.type.name !== 'Текстовый'">
              <input
                :type="
                  question.type.name === 'Одиночный' ? 'radio' : 'checkbox'
                "
                class="real-checkbox"
                :key="answer.isRightQuestion"
                :checked="answer.isRightQuestion"
                @click.prevent="
                  question.type.name === 'Одиночный' &&
                  getRightQuestionsCounter === 1
                    ? unselectAll(answer)
                    : (answer.isRightQuestion = !answer.isRightQuestion)
                "
              />
              <span
                :class="
                  question.type.name === 'Одиночный'
                    ? 'custom-radio'
                    : 'custom-checkbox'
                "
              ></span>
            </template>
            <input
              type="text"
              class="PossibleAnswer"
              v-model="answer.text"
              placeholder="Ответ"
            />

            <span
              class="close"
              v-if="
                question.type.name !== 'Текстовый' &&
                  question.answers.length > 1 &&
                  questionData == null
              "
              @click="deleteQuestion(index)"
            ></span>
          </label>
        </div>
      </div>
      <ButtonGradient
        v-if="question.type.name != 'Текстовый' && questionData == null"
        class="ButtonQuestField noselect"
        ButtonText="Добавить вариант ответа"
        @click="addAnswer"
      />
      <ButtonGradientBorder
        v-if="questionData == null && questionSetupIndex != -1"
        style="margin-top: 70px;"
        class="noselect"
        ButtonText="Удалить вопрос"
        @click="removeQuestionSetup(questionSetupIndex)"
      />

      <!-- <ButtonGradient class="ButtonAddAnswer" ButtonText="Добавить вариант ответа" /> -->
    </div>

    <div class="CardTypeQuestion">
      <div class="LineSearch" style="display: none;">
        <input
          class="QuestionField QuestionFieldMobile"
          placeholder="Поиск в базе вопросов"
          type="text"
        />
        <!-- <img class="filters-img" src="@/../../../assets/img/Setup/search.svg" alt="v" /> -->
      </div>

      <div
        class="VarAnswer"
        :style="
          questionData == null
            ? ''
            : `cursor: default !important; opacity: 0.5;`
        "
      >
        <span class="SetupTextPoint" style="margin-top: 0px;">Вид ответа</span>
        <div class="RightPanel">
          <span
            v-for="(type, indx) in question_types"
            :key="`${indx}${type.id}${type.name}`"
            @click="questionData == null ? changeQuestionType(type) : null"
            class="violet-color"
            :class="{
              'violet-color-solid': type.id === question.type.id,
            }"
            >{{ type.name }} ответ</span
          >
        </div>
        <div class="RightPanelMobile">
          <span
            @click="updFilters($event)"
            class="violet-color"
            :class="{
              'violet-color-solid': true,
            }"
            >Один
          </span>

          <span
            @click="updFilters($event)"
            class="violet-color"
            :class="{
              'violet-color-solid': true,
            }"
            >Несколько
          </span>

          <span
            @click="updFilters($event)"
            class="violet-color"
            :class="{
              'violet-color-solid': true,
            }"
            >Текст
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAllQuestionTypes } from "@/api/user_tests.js";
import ButtonGradient from "@/components/CustomElements/ButtonGradient.vue";
import ButtonGradientBorder from "@/components/CustomElements/ButtonGradientBorder.vue";

export default {
  props: {
    questionData: {
      type: Object,
      default: null,
    },
    removeQuestionSetup: {
      type: Function,
      default: () => {},
    },
    questionSetupIndex: {
      type: Number,
      default: null,
    },
  },
  components: {
    ButtonGradient,
    ButtonGradientBorder,
  },
  async created() {
    let _question_types = await getAllQuestionTypes();
    this.question_types = _question_types.data;

    this.question.type.id = this.getSortedQuestionTypes[0].id;
    this.question.type.name = this.getSortedQuestionTypes[0].name;

    if (this.questionData != null) {
      this.question = this.questionData;
    }
  },
  data() {
    return {
      question: {
        name: "",
        type: {
          id: "",
          name: "",
        },
        answers: [
          {
            order: 0,
            text: "",
            isRightQuestion: false,
          },
        ],
      },

      question_types: [],

      checkboxes_updater: 0,
    };
  },
  methods: {
    addAnswer() {
      this.question.answers.push({
        order:
          this.question.answers[this.question.answers.length - 1].order + 1,
        text: "",
        isRightQuestion: false,
      });
    },

    changeQuestionType(type) {
      for (let i of this.question.answers) {
        i.isRightQuestion = false;
      }

      switch (type.name) {
        case "Одиночный": {
          break;
        }
        case "Множественный": {
          break;
        }
        case "Текстовый": {
          if (this.question.answers.length > 1) {
            this.question.answers.splice(1, this.question.answers.length - 1);
          }
          this.question.answers[0].isRightQuestion = true;
          break;
        }
      }

      this.question.type.id = type.id;
      this.question.type.name = type.name;

      this.checkboxes_updater += 1;
    },

    unselectAll(selectThis) {
      for (let i of this.question.answers) {
        i.isRightQuestion = false;
      }

      selectThis.isRightQuestion = true;
    },

    deleteQuestion(index) {
      if (index !== -1) {
        // если объект найден
        this.question.answers.splice(index, 1); // удаляем объект из массива
      }
    },
  },
  computed: {
    getSortedQuestionTypes() {
      return this.question_types.sort((a, b) => a.id - b.id);
    },

    getRightQuestionsCounter() {
      let c = 0;

      for (let i of this.question.answers) {
        if (i.isRightQuestion === true) {
          c++;
        }
      }

      return c;
    },
  },
  mounted() {},
};
</script>

<style scoped>
.close {
  cursor: pointer;
  position: relative;
  width: 24px;
  height: 24px;
  opacity: 1;
}
.close:hover {
  opacity: 1;
}
.close:before,
.close:after {
  position: absolute;
  left: 11px;
  content: " ";
  height: 33px;
  width: 2px;
  background-color: #9d2c2c;
  top: -4px;
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}

.LineSearch {
  display: flex;
  flex-direction: row;
}

.VarAnswer {
  display: flex;
  flex-direction: column;
}
.filters-img {
  position: absolute;
  margin-right: 37px;
  margin-top: 12px;
  z-index: 1;
  right: 0;
}

/* .active {
  background-color: #413e74;
} */
.SetupQuestion {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  background: rgba(31, 30, 72, 0.8);
  border-radius: 20px;
  width: auto;
  height: fit-content;
  position: relative;
  overflow: hidden;
  padding: 30px;
  justify-content: space-between;
  gap: 60px;
}

.CardTypeQuestion {
  display: flex;
  flex-direction: column;
  width: 40%;
}

.RightPanel {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}
.RightPanelMobile {
  display: none;
}

.SetupTextPoint {
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  line-height: 24.38px;
  margin: 20px 0px 10px 0px;
}

/* .ButtonQuest:active {
  background: #413e74;
}
 */
.QuestionField {
  display: flex;
  background: var(
    --input,
    linear-gradient(
      269deg,
      rgba(41, 37, 88, 0.54) 0%,
      rgba(38, 35, 83, 0.87) 40%,
      rgba(40, 38, 89, 0.5) 100%
    )
  );
  border-radius: 4px;
  border-style: solid;
  border-color: #413e74;
  border-width: 1px;
  padding: 12px;
  width: 100%;
  height: 48px;
  top: 30px;

  color: #ffffff;
  font-family: "Inter-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 30px;
  line-height: 22px;
}

.QuestionField::placeholder {
  color: #667080;
}

/*Чекбокс*/

.SetupCheck {
  display: flex;
  flex-direction: row;
  color: #ffffff;
  gap: 20px;
  width: 100%;
}

.SetupCheckText {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  width: 100%;
}

.real-checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  position: absolute;
}

.custom-checkbox {
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  background: #f8f7f700;
  border: 1px solid #312e57;
  cursor: pointer;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.custom-radio {
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background: #f8f7f700;
  border: 1px solid #312e57;
  cursor: pointer;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

/*Состояния чекбокса*/

.real-checkbox:checked + .custom-checkbox,
.real-checkbox:checked + .custom-radio {
  background-image: url("./../../assets/img/Setup/vector.svg");
  background-position: center;
}

.CardAddAnswer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.AddVariable {
  position: relative;
  width: 100%;
  padding: 20px 0px 15px 0px;
}

.PossibleAnswer {
  display: flex;
  background: var(
    --input,
    linear-gradient(
      269deg,
      rgba(41, 37, 88, 0) 0%,
      rgba(38, 35, 83, 0) 40%,
      rgba(40, 38, 89, 0) 100%
    )
  );
  padding: 5px 0px;
  flex-grow: 1;
  flex-shrink: 0;
  height: 28px;

  border: none;
  outline: none;
  border-bottom: 1px solid #413e74;

  color: #ffffff;
  font-family: "Inter-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 30px;
  line-height: 22px;
}

.PossibleAnswer::placeholder {
  color: #667080;
}
</style>

<style scoped>
.violet-color {
  border-color: #413e74;
  color: #ffffff;

  cursor: pointer;
  position: relative;
  width: fit-content;
  padding: 6px 16px;

  border-radius: 4px;
  border: 0px solid;
  backdrop-filter: blur(1px);

  font-size: 15px;
  font-weight: 400;
}
.violet-color::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 4px;
  padding: 1px;
  background: linear-gradient(221.26deg, #413e74 -3.4%, #413e74 101.08%);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
}

.violet-color-solid {
  background-color: #413e74;
}
.ButtonQuestField {
  padding: 6px 16px;
}
</style>

<style scoped>
@media (max-width: 479px) {
  .SetupTest {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    padding: 20px;
  }
  .SetupQuestion {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;

    justify-content: space-between;
    gap: 20px;
    padding: 20px;
  }
  .CardTypeQuestion {
    display: flex;
    flex-direction: column-reverse;
    gap: 0;
    width: 100%;
  }
  .QuestionField {
    font-size: 13px;
  }
  .QuestionFieldMobile {
    display: none;
  }
  .violet-color {
    display: flex;
    width: 100%;
    justify-content: center;
    font-size: 12px;
  }
  .RightPanel {
    display: none;
  }
  .RightPanelMobile {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 8px;
  }
  .SetupTextPoint[data-v-707378fc] {
    margin: 10px 0px;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .SetupTest {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .SetupQuestion {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;

    justify-content: space-between;
    gap: 20px;
  }
  .CardTypeQuestion {
    display: flex;
    flex-direction: column-reverse;
    gap: 20px;
    width: 100%;
  }
  .QuestionFieldMobile {
    display: none;
  }
  .violet-color {
    display: flex;
    width: 100%;
    justify-content: center;
  }
  .RightPanel {
    display: none;
  }
  .RightPanelMobile {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 8px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .SetupTest {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .SetupQuestion {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
  }
  .CardTypeQuestion {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 40%;
  }
  .QuestionField {
    width: auto;
  }
}
@media (min-width: 992px) and (max-width: 1399px) {
  .SetupTest {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .SetupQuestion {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
  }
  .CardTypeQuestion {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 40%;
  }
}
@media (min-width: 1400px) {
  .SetupTest,
  .SetupQuestion {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
  }
}
</style>
